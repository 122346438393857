import {
  useWindowSize as windowSizeNative
} from '@react-hook/window-size';

const useWindowSize = () => {
  const [width, height] = windowSizeNative()
  return { width, height };
}

const useIsMobile = () => {
  const { width } = useWindowSize();
  return (width <= 767);
}

export { useWindowSize, useIsMobile };
