
import React from 'react';
import '../../css/components/Home/Footer.css';

const Footer = ({ }) => {
  return (<div className="footer-container a-center-d">

    <div className="max-1024-d a-left-d">
      <p className="footer-title">Enjoy 7 Palmas Shopping Centre, a unique commercial space where you can spend moments of happiness and joy with your friends and family. Being a reference in the Canary Islands, you will have an unforgettable and inspiring experience discovering our great commercial, gastronomic and leisure offer.</p>
    </div>

    <div className="footer-cc-bg"></div>

    <div className="footer-content">
      <div className="number-row-container">
        <div className="number-shops-icon"></div>
        <div className="number-content">
          <h2 className="number-title">90</h2>
          <p className="number-subtitle">shops and restaurants distributed over 4 floors</p>
        </div>
      </div>
      <div className="number-row-container number-row-container-two">
        <div className="number-visitors-icon"></div>
        <div className="number-content">
          <h2 className="number-title">8M</h2>
          <p className="number-subtitle">annual visitors</p>
        </div>
      </div>
      <div className="onda-blanca" />
    </div>
    <div className="text-enjoy-rrss-container">
      <p className="text-enjoy">Enjoy your visit and your stay in Gran Canaria!</p>
      <div className="rrss-container">
        <a href="https://twitter.com/cc7palmas?s=09" className="twitter_icon"></a>
        <a href="https://www.facebook.com/cc7palmas/" className="facebook_icon"></a>
        <a href="https://www.instagram.com/cco7palmas" className="instagram_icon"></a>
      </div>
    </div>
  </div>)
}

export default Footer;