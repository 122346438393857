
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormSearch from '../components/Offers/FormSearch';
import Header from '../components/Offers/Header';
import List from '../components/Offers/List';
import { useSettings } from '../context/MenuContext';
import { PANEL_URL } from '../tools/Constants';

const getCategoriasUrl = `${PANEL_URL}/categorias`

const Offers = ({ }) => {
  const { settings, saveSettings } = useSettings();
  const { id } = useParams();

  const updateProductsAndSubcategories = async (id) => {
    const getComerciosUrl = `${PANEL_URL}/comercios?categoria.uid=${id}`;
    const comerciosResponse = await fetch(getComerciosUrl);
    let comercios = [];
    let subcategorias = [];
    let categories = [];
    try {
      const items = await comerciosResponse.json();
      comercios = items;
    }
    catch (err) { }

    const getSubcategoriasUrl = `${PANEL_URL}/subcategorias?categoria.uid=${id}`;
    const subcategoriasResponse = await fetch(getSubcategoriasUrl);

    try {
      const items = await subcategoriasResponse.json();
      subcategorias = items;
    }
    catch (err) { }

    const getCategoriasUrl = `${PANEL_URL}/categorias`;
    const categoriasResponse = await fetch(getCategoriasUrl);

    try {
      const items = await categoriasResponse.json();
      categories = items;
    }
    catch (err) { }

    let menuSelected = null;
    if(id && categories && categories.length !== 0) {
      menuSelected = categories.find(c => c.uid === id);
    }

    saveSettings({ subcategorias, comercios, subcategoriaSelect: [], categories, menuSelected });
  }

  useEffect(() => {
    if (settings.comercios === undefined || settings.comercios === null) {
      updateProductsAndSubcategories(id);
    }
  }, []);


  return (<>
    <Header />
    <FormSearch />
    <List />
  </>)
}

export default Offers;