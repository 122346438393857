
import '../../css/components/Offers/OfferItem.css';
import { PANEL_URL } from '../../tools/Constants';

const categorySvgs = {
  "food-and-drink": "/svg/food-drink-category.svg",
  "leisure": "/svg/leisure-category.svg",
  "shops": "/svg/shops-category.svg",
}

const getCategorySvg = (categoryUid) => {
  if (categorySvgs[categoryUid]) {
    return categorySvgs[categoryUid];
  }
  return null;
}

const renderPointInfo = (icon, text, url) => {

  const imgStyle = {
    width: '17px',
    height: '17px',
    marginRight: '10px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${icon})`,
  }

  if (url) {
    return (<div className="offer-item-info-container">
      <div style={imgStyle} />
      <a href={url} target="_blank"><p className="offer-item-point-info">{text}</p></a>
    </div>)
  }

  return (<div className="offer-item-info-container">
    <div style={imgStyle} />
    <p className="offer-item-point-info">{text}</p>
  </div>)
}

const OfferItem = ({ item, animation }) => {
  const {
    key,
    id,
    name,
    subcategoria,
    categoria,
    tituloOferta_a,
    descripcionOferta_a,
    descripcionOferta_b,
    tituloOferta_b,
    direccion,
    horario,
    web,
    title,
    foto,
    body,
  } = item;

  const pictureUrl = `${PANEL_URL}${foto.url ? foto.url : ""}`;

  const imgStyle = {
    marginLeft: '40px',
    marginRight: '40px',
    height: '100px',
    // marginBottom: '15px',
    borderTopLeftRadius: '26px',
    borderTopRightRadius: '26px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${pictureUrl})`,
  }

  const categorySvg = getCategorySvg(categoria.uid);

  const extraProps = animation ? { "data-aos": "fade-up" } : {}

  return <div key={key} {...extraProps} className="offer-item-container">
    <div className="offer-item-container-top">
      <div style={imgStyle} />
      {renderPointInfo(categorySvg, subcategoria.nombre)}
      {renderPointInfo("/svg/location.svg", direccion)}
      {renderPointInfo("/svg/horario.svg", horario)}
      {web && web.includes("http") && renderPointInfo("/svg/website.svg", "Visit website", web)}
    </div>
    <div className="offer-item-offers-container">
      {(tituloOferta_a || descripcionOferta_a) && <div className="offer-item-body-container">
        {tituloOferta_a && <p className="offer-item-body-title">{tituloOferta_a}</p>}
        {descripcionOferta_a && <p className="offer-item-body-subtitle">{descripcionOferta_a}</p>}
      </div>}
      {(tituloOferta_b || descripcionOferta_b) && <div className="offer-item-offers-separador" />}
      {(tituloOferta_b || descripcionOferta_b) && <div className="offer-item-body-container">
        {tituloOferta_b && <p className="offer-item-body-title">{tituloOferta_b}</p>}
        {descripcionOferta_b && <p className="offer-item-body-subtitle">{descripcionOferta_b}</p>}
      </div>}
    </div>
  </div>
}

export default OfferItem;