
import * as _ from 'lodash';
import React from 'react';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Offers/List.css';
import { useIsMobile } from '../../tools/ScreenUtils';
import OfferItem from './OfferItem';

const filterComercio = (comercio) => {
  comercio.tituloOferta_a = comercio.tituloOferta_a === "" ? null : comercio.tituloOferta_a;
  comercio.descripcionOferta_a = comercio.descripcionOferta_a === "" ? null : comercio.descripcionOferta_a;
  comercio.tituloOferta_b = comercio.tituloOferta_b === "" ? null : comercio.tituloOferta_b;
  comercio.descripcionOferta_b = comercio.descripcionOferta_b === "" ? null : comercio.descripcionOferta_b;
  return comercio
}

const List = ({ }) => {
  const { settings, saveSettings } = useSettings();
  const isMobile = useIsMobile();
  let comercios = settings && settings.comercios ? settings.comercios : null;

  console.log('comercios', comercios);
  console.log('settings.subcategoriaSelect', settings.subcategoriaSelect);
  if (settings.subcategoriaSelect && settings.subcategoriaSelect.value) {
    comercios = comercios.filter(c => c.subcategoria.id === settings.subcategoriaSelect.value);
  }

  if (settings.checkOffers && settings.subcategoriaSelect) {
    comercios = comercios.filter(c => (c.tituloOferta_a !== null || c.tituloOferta_b !== null));
  }

  if (comercios) {
    comercios = comercios.map(c => filterComercio(c));
    comercios = _.orderBy(comercios, ['tituloOferta_a', 'descripcionOferta_a', 'nombre'], ['asc', 'asc', 'nombre']);
  }

  if (isMobile) {
    return (<div className="list-container">
      {comercios && comercios.length !== 0 && comercios.map(c => <OfferItem animation key={`${c.id}-comercio-item`} item={c} />)}
      {(comercios === null || comercios && comercios.length) === 0 && <div className="sorry-container">
        <div className="face-sad-icon" />
        <p className="sorry-message">Sorry! At the moment there are no deals available for your selection</p>
      </div>}

    </div>)
  }

  const list = [];
  if (comercios) {
    let listIndex = 0;
    for (const comercio of comercios) {
      const indexArray = list[listIndex] || [];
      indexArray.push(comercio);
      list[listIndex] = indexArray;
      listIndex++;
      if (listIndex > 2) {
        listIndex = 0;
      }
    }
  }

  if (list.length === 2) {
    list.push([]);
  }

  return (<div className="list-container">
    <div className="list-container-desktop">
      <div class="boardz">
        {list.map(l => <ul>{l && l.map(c => <li><OfferItem animation={false} key={`${c.id}-comercio-item`} item={c} /></li>)}</ul>)}
      </div>
      {(comercios === null || comercios && comercios.length) === 0 && <div className="sorry-container">
        <div className="face-sad-icon" />
        <p className="sorry-message">Sorry! At the moment there are no deals available for your selection</p>
      </div>}
    </div>

  </div>)
}

export default List;