
import React from 'react';
import { IoArrowDownSharp, IoMenuOutline } from "react-icons/io5";
import { useHistory } from 'react-router-dom';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Home/Header.css';
import { PANEL_URL } from '../../tools/Constants';

const Header = ({ }) => {
  const history = useHistory();
  const { settings, saveSettings } = useSettings();
  const onClick = () => {
    const isVisible = settings && settings.isVisible ? false : true;
    saveSettings({ isVisible });
  }

  const updateProductsAndSubcategories = async (category) => {
    const getComerciosUrl = `${PANEL_URL}/comercios?categoria=${category.id}`;
    const comerciosResponse = await fetch(getComerciosUrl);
    let comercios = [];
    let subcategorias = [];
    try {
      const items = await comerciosResponse.json();
      comercios = items;
    }
    catch (err) { }

    const getSubcategoriasUrl = `${PANEL_URL}/subcategorias?categoria=${category.id}`;
    const subcategoriasResponse = await fetch(getSubcategoriasUrl);

    try {
      const items = await subcategoriasResponse.json();
      subcategorias = items;
    }
    catch (err) { }

    saveSettings({ subcategorias, comercios, isVisible: false, menuSelected: category });
  }

  const onClickMenu = (category) => {
    history.push(`/offer/${category.uid}`);
    updateProductsAndSubcategories(category);
  }


  return (<div className="header-container">

    <div className="header-top">
      <div className="header-logo-white"></div>
      <button className="button-action menu-action-mobile" onClick={onClick}>
        <IoMenuOutline size={30} color={"#FFF"} />
      </button>
      <div className="menu-action-desktop">
        {settings.categories && settings.categories.map(c => <a key={`menu-d-action-${c.id}`} className="menu-desktop-item" onClick={() => onClickMenu(c)}>{c.nombre}</a>)}
      </div>
    </div>

    <h3 className="hello-there">Hello there!</h3>

    <div className="header-flecha animate__animated animate__shakeY animate__delay-0.5s animate__slower animate__repeat-3">
      <div className="header-flecha-icon" />
      {false && <IoArrowDownSharp size={50} color={"#001489"} />}
    </div>

  </div>)
}

export default Header;