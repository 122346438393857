
import React from 'react';
import CardList from '../components/Home/CardList';
import Footer from '../components/Home/Footer';
import Header from '../components/Home/Header';
import Stories from '../components/Home/Stories';

const Home = ({ }) => {
  return (<>
    <Header />
    <CardList />
    <Stories />
    <Footer />
  </>)
}

export default Home;