
import React, { useEffect, useState } from 'react';
import Stories from 'react-insta-stories';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Home/Stories.css';
import { PANEL_URL } from '../../tools/Constants';
import { useIsMobile, useWindowSize } from '../../tools/ScreenUtils';

const getCategoriasUrl = `${PANEL_URL}/slider`;

const getSizes = ({ isMobile, width, height }) => {
  if (isMobile) {
    return { width, height: width * 1.7 };
  }
  return {
    width: 486,
    height: 864
  }
}

const StoriesComponent = ({ }) => {
  const { width, height } = useWindowSize();
  const isMobile = useIsMobile();
  const { settings, saveSettings } = useSettings();
  const [videos, setVideos] = useState([]);
  const sizes = getSizes({ width, height, isMobile })

  useEffect(() => {
    fetch(getCategoriasUrl).then(response => {
      console.log('response', response);
      if (response.status === 200) {
        try {
          response.json().then(items => {
            console.log('items', items);
            const imagenes = items.imagenes.map(v => `${PANEL_URL}${v.url}`)
            setVideos(imagenes);
            saveSettings({ videos: imagenes });
            console.log('imagenes', imagenes);
          })
        }
        catch (err) { }
      }
    })
  }, []);


  return (<div className="stories-container a-center-d">
    {videos && videos.length !== 0 && <Stories
      // stories={["https://i.pinimg.com/originals/91/68/0c/91680c55372fd69e0ade4ee384ce1afb.jpg", "https://picsum.photos/1080/1920?b", "https://picsum.photos/1080/1920?c"]}
      stories={videos}
      defaultInterval={3000}
      height={sizes.height}
      width={sizes.width}
      // isPaused={false}
      loop={true}
    />}
  </div>)
}

export default StoriesComponent;