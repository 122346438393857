
import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import Modal from 'react-modal';
import { useHistory } from "react-router-dom";
import { useSettings } from '../context/MenuContext';
import '../css/components/MenuModal.css';
import { PANEL_URL } from '../tools/Constants';
import { useWindowSize } from '../tools/ScreenUtils';

const getCategoriasUrl = `${PANEL_URL}/categorias`;

const getSubcategoriasUrl = `${PANEL_URL}/subcategorias`;

const MenuModal = ({ }) => {
  const history = useHistory();
  const [animate, setAnimate] = useState(false);
  const { width, height } = useWindowSize();
  const { settings, saveSettings } = useSettings();

  const styleFade = settings.isVisible && animate === false ? "menu-modal-container animate__animated animate__fadeInRight animate__faster" : "menu-modal-container animate__animated animate__fadeOutRight animate__faster";

  const updateProductsAndSubcategories = async (category) => {
    const getComerciosUrl = `${PANEL_URL}/comercios?categoria=${category.id}`;
    const comerciosResponse = await fetch(getComerciosUrl);
    let comercios = [];
    let subcategorias = [];
    try {
      const items = await comerciosResponse.json();
      comercios = items;
    }
    catch (err) { }

    const getSubcategoriasUrl = `${PANEL_URL}/subcategorias?categoria=${category.id}`;
    const subcategoriasResponse = await fetch(getSubcategoriasUrl);

    try {
      const items = await subcategoriasResponse.json();
      subcategorias = items;
    }
    catch (err) { }

    saveSettings({ subcategorias, comercios, isVisible: false, menuSelected: category, subcategoriaSelect: [], checkOffers: false });
  }

  const onClickClose = () => {
    const isVisible = settings && settings.isVisible ? false : true;
    saveSettings({ isVisible });
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1500);
  }

  const onClickMenu = (category) => {
    history.push(`/offer/${category.uid}`);
    updateProductsAndSubcategories(category);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1500);
  }

  const showModal = animate || settings.isVisible;

  useEffect(() => {
    fetch(getCategoriasUrl).then(response => {
      console.log('response', response);
      if (response.status === 200) {
        try {
          response.json().then(items => {
            saveSettings({ categories: items });
            console.log('items', items);
          })
        }
        catch (err) { }
      }
    })
  }, [])

  const categories = settings.categories || null;

  return (
    <>
      <Modal
        isOpen={showModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className={styleFade}>

          <div className="menu-modal-top">
            <div className="menu-modal-logo-color"></div>

            <button className="button-action" onClick={onClickClose}>
              <IoClose size={30} color={"#001489"} />
            </button>

          </div>

          <div className="menu-links">
            {categories && categories.length > 0 && categories.map(c => (
              <div key={`${c.id}-${c.uid}`} className="link-menu-item">
                <div className="link-menu-item-foto-container">
                  {c.icon && <img src={`${PANEL_URL}${c.icon.url}`} className="link-menu-item-foto" className="link-menu-item-foto" />}
                </div>
                <a onClick={() => onClickMenu(c)}>{c.nombre}</a>
              </div>
            ))}
          </div>

        </div>
      </Modal></>)
}

const customStyles = {
  overlay: {
    backgroundColor: null,
  },
  content: {
    backgroundColor: null,
    height: '100%',
    width: '100%',
    padding: '0',
    margin: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0'
  },
};

export default MenuModal;