
import React from 'react';
import Select from 'react-select';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Offers/FormSearch.css';
// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

const Checkbox = props => <input type="checkbox" {...props} />;

const colourStyles = {
  // control: styles => ({ ...styles, backgroundColor: 'white' }),
  input: styles => ({
    ...styles,
    color: '#001489',
    borderColor: '#001489',
    borderRadius: 35,
    borderWidth: 1,
  }),
  option: styles => ({
    ...styles,
    color: '#001489',
    fontFamily: 'Montserrat'
    // borderColor: '#001489',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#001489',
    borderColor: '#001489',
    borderRadius: 35,
  }),
  control: styles => ({
    ...styles,
    color: '#001489',
    borderColor: '#001489',
    borderWidth: 1,
    borderRadius: 20,
    marginTop: 10,
  }),
  placeholder: styles => ({ ...styles, color: '#001489', }),
  // singleValue: (styles, { data }) => ({ ...styles }),
};

const FormSearch = ({ }) => {
  const { settings, saveSettings } = useSettings();
  const onClick = () => {
    const isVisible = settings && settings.isVisible ? false : true;
    // saveSettings({ isVisible });
  }
  const onChangeCheckBox = (event) => {
    const newState = !settings.checkOffers;
    saveSettings({ checkOffers: newState });
  }
  const onChangeSelect = (items) => {
    saveSettings({ subcategoriaSelect: items });
  }

  const options = settings && settings.subcategorias ?
    settings.subcategorias.map(s => ({ value: s.id, label: s.nombre })) : [];

  return (<div className="formSearch-container-top">
    <div className="formSearch-container">
      <label className="select-label" for="select-label">Filter</label>
      <Select
        // isMulti
        value={settings.subcategoriaSelect}
        onChange={onChangeSelect}
        isClearable={true}
        styles={colourStyles}
        id="select-label"
        options={options} />

      <div className="checkbox-container">
        <label>
          <Checkbox
            styles={colourStyles}
            checked={settings.checkOffers}
            onChange={onChangeCheckBox}
            id="cypress-single__disabled-checkbox"
          />
          <span className="checkbox-container-label">Deal available</span>
        </label>
      </div>

      {false && <button className="buscar-btn" onClick={onClick}>
        Search
      </button>}
    </div>
  </div>)
}

export default FormSearch;