
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Home/CardItem.css';
import { PANEL_URL } from '../../tools/Constants';

const CardItem = ({ key, item }) => {
  const history = useHistory();
  const { settings, saveSettings } = useSettings();

  console.log('item', item);
  const {
    id,
    nombre,
    orden,
    uid,
    titulo_home,
    categoria,
    descripcion_home,
    boton_home,
    imagen_home,
  } = item;

  const pictureUrl = `${PANEL_URL}${imagen_home.url ? imagen_home.url : ""}`;

  const updateProductsAndSubcategories = async (category) => {
    const getComerciosUrl = `${PANEL_URL}/comercios?categoria=${category.id}`;
    const comerciosResponse = await fetch(getComerciosUrl);
    let comercios = [];
    let subcategorias = [];
    try {
      const items = await comerciosResponse.json();
      comercios = items;
    }
    catch (err) { }

    const getSubcategoriasUrl = `${PANEL_URL}/subcategorias?categoria=${category.id}`;
    const subcategoriasResponse = await fetch(getSubcategoriasUrl);

    try {
      const items = await subcategoriasResponse.json();
      subcategorias = items;
    }
    catch (err) { }

    saveSettings({ subcategorias, comercios });
  }

  const onClickMenu = (category) => {
    if (category) {
      history.push(`/offer/${category.uid}`);
      saveSettings({ isVisible: false, menuSelected: category, subcategoriaSelect: [], checkOffers: false });
      updateProductsAndSubcategories(category);
    }
  }

  const imgStyle = {
    width: '100%',
    height: '200px',
    borderTopLeftRadius: '26px',
    borderTopRightRadius: '26px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${pictureUrl})`,
  }

  return (<div key={key} className="carditem-container" data-aos="fade-up">
    <div style={imgStyle} />
    <div className="carditem-content">
      <p className="carditem-title">{titulo_home}</p>
      {descripcion_home && descripcion_home !== "" && <p className="carditem-subtitle">{descripcion_home}</p>}
      <button className="button-action" onClick={() => onClickMenu(item)}><div className="carditem-action">{boton_home}</div></button>
    </div>

  </div>)
}

export default CardItem;