import React from "react";
import {
  BrowserRouter as Router, Route,
  Switch
} from "react-router-dom";
import './App.css';
import MenuModal from "./components/MenuModal";
import { SettingsContext, SettingsProvider } from "./context/MenuContext";
import Home from './views/Home';
import Offers from "./views/Offers";

export default function App() {

  return (
    <SettingsProvider settings={SettingsContext}>
      <Router>
        <Switch>
          <Route path="/offer/:id">
            <Offers />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <MenuModal />
      </Router>
    </SettingsProvider>
  );
}