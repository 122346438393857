// Settings Context - src/context/Settings
import React, { useContext, useState } from "react";


const SettingsContext = React.createContext();

const defaultSettings = {
  isVisible: false,
  checkOffers: false,
  subcategoriaSelect: [],
  comercios: [],
  categories: [],
  subcategorias: [],
};

export const SettingsProvider = ({ children, settings }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const saveSettings = (values) => {
    setCurrentSettings({ ...currentSettings, ...values })
  };

  return (
    <SettingsContext.Provider
      value={{ settings: currentSettings, saveSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

const useSettings = () => {
  const context = useContext(SettingsContext);
  return context;
  // const { settings, saveSettings } = context;
  // const saveSettingsCustom = (newValues) => saveSettings({ ...settings, ...newValues });
  // return { settings, saveSettings: saveSettingsCustom };
};

export default SettingsContext;

export {
  SettingsContext,
  useSettings,
};
