
import React from 'react';
import { useSettings } from '../../context/MenuContext';
import '../../css/components/Home/CardList.css';
import CardItem from './CardItem';

const CardList = ({ }) => {
  const { settings, saveSettings } = useSettings();
  const items = settings && settings.categories ? settings.categories : [];

  return (<div className="cards-container a-center-d">

    <div className="max-1024-d">
      <div className="a-left-d">
        <p className="cards-title">We are excited to welcome you and we’d love that you enjoy your visit to the fullest with these exclusive deals:</p>
        <div className="cards-items-container">
          {items && items.map(i => (<CardItem key={`${i.id}-categoria-home`} item={i} />))}
        </div>
      </div>
    </div>




  </div>)
}

export default CardList;